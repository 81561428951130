import React from "react"
import ContactDetails from "./ContactDetails"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className="m-auto max-w1080px px4 px10-md pt6 mb20">
      <div className="border-top mb6" />
      <ContactDetails />
      <div className="maison-book grey-500 fz14px lh24px pt2">
        © {year} Keith Holjencin.
        <br />
        Built with{" "}
        <a
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
          className="grey-500 hover-text-decoration-none"
        >
          React
        </a>
        , and{" "}
        <a
          href="https://www.gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
          className="grey-500 hover-text-decoration-none"
        >
          Gatsby
        </a>
        . Hosted on{" "}
        <a
          href="https://www.netlify.com"
          target="_blank"
          rel="noopener noreferrer"
          className="grey-500 hover-text-decoration-none"
        >
          Netlify
        </a>
        .
      </div>
    </div>
  )
}

export default Footer
