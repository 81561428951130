import React from "react"
import Paragraph from "../components/portfolio/Text"
import Heading from "../components/portfolio/Text"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mb10 pt12-sm pt25-md" />
    <h1 className="maison-demi fz39px lh48px fz48px-md lh54px-md fz60px-lg lh66px-lg font-weight-normal ls-xtight grey-900 block ls_1 mb3">
      404
    </h1>
    <Paragraph className="block">
      Oops! You just tried to land on a page that does not exist.
    </Paragraph>
    <Heading kind="pHeading3" className="block">
      <Link to="/" className="blue-500 inline-block">
        Return to my portfolio
      </Link>
    </Heading>
  </Layout>
)

export default NotFoundPage
