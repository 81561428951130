import { styles } from "../../utils/css"

const TEXT_STYLES = {
  body: {
    classes: styles("fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb3"),
    bold: styles("maison-demi"),
    normal: styles("maison-book"),
  },
  pHeading1: {
    classes: styles(
      "maison-demi grey-900 fz48px lh48px fz42px-sm fz48px-md lh54px-md fz54px-lg lh56px-lg ls-xtight block mb2"
    ),
  },
  pHeading2: {
    classes: styles("maison-demi grey-900 fz28px lh36px mt2 mt2-md mb2 mb7-sm"),
  },
  pHeading3: {
    classes: styles("maison-demi grey-900 fz21px fz23px-sm lh32px mb2"),
  },
  menuParagraph: {
    classes: styles("maison-book grey-900 fz21px lh32px mb2"),
  },
  captionTitle: {
    classes: styles(
      "fz28px ls36px fz34px-sm ls42px-sm fz38px-lg ls48px-lg maison-demi grey-900 mb5 mb5-md mt5 mt6-md"
    ),
  },
  collaborators: {
    classes: styles("maison-book grey-900 fz14px lh24px"),
  },
}

export default TEXT_STYLES
