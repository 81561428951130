import React from "react"
import { styles } from "../../utils/css"
import TEXT_STYLES from "./TEXT_STYLES"

const Text = ({
  tag: Tag = "span",
  kind = "body",
  children,
  style,
  className,
  bold = false,
}) => {
  const classes = styles(
    TEXT_STYLES[kind].classes,
    bold === true ? TEXT_STYLES[kind].bold : TEXT_STYLES[kind].normal,
    className
  )
  return (
    <Tag className={classes} style={style}>
      {children}
    </Tag>
  )
}

export default Text
export const Paragraph = props => <Text tag="p" {...props} />
export const Div = props => <Text tag="div" {...props} />
export const Span = props => <Text tag="span" {...props} />
export const Li = props => <Text tag="li" {...props} />
export const Heading = props => {
  const level = props.level ? props.level : "2"
  return <Text tag={`h${level}`} {...props} />
}
