/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// import Header from "./header"
// import "../components/layout.css"
import Footer from "./portfolio/Footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div className="m-auto max-w1080px px4 px10-md">
          <main className="mb4">{children}</main>
          {/* <footer className="maison-book grey-500 fz14px">
            © {new Date().getFullYear()} Keith Holjencin. Built with
            {` `}
            <a href="https://www.gatsbyjs.org" className="grey-500">
              Gatsby
            </a>
          </footer> */}
        </div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
