import React from "react"
import svgEmail from "../../images/email.svg"
import svgGithub from "../../images/github.svg"
import svgTwitter from "../../images/twitter.svg"

const ContactDetails = () => {
  return (
    <ul>
      <li className="inline-block mb1 mr4 mr3-sm">
        <a
          href="mailto:keith@holjencin.com"
          className="grey-500 text-decoration-none hover-text-underline hover-blue-500"
          title="Email me"
        >
          <img src={svgEmail} className="w15px h-auto mr7px" alt="Email icon" />
          <span className="maison-book fz15px lh24px hide-sm">
            keith@holjencin.com
          </span>
        </a>
      </li>
      <li className="inline-block-sm mb1 mr4 mr3-sm">
        <a
          href="https://github.com/keithholjencin"
          target="_blank"
          rel="noopener noreferrer"
          className="grey-500 text-decoration-none hover-text-underline hover-blue-500"
          title="Visit my GitHub profile"
        >
          <img
            src={svgGithub}
            className="w14px h-auto mr5px"
            alt="GitHub icon"
          />
          <span className="maison-book fz15px lh24px hide-sm">
            @keithholjencin
          </span>
        </a>
      </li>
      <li className="inline-block-sm hide mb1 mr4 mr3-sm">
        <a
          href="https://twitter.com/keithholjencin"
          target="_blank"
          rel="noopener noreferrer"
          className="grey-500 text-decoration-none hover-text-underline hover-blue-500"
          title="Visit my Twitter profile"
        >
          <img
            src={svgTwitter}
            className="w14px h-auto mr5px"
            alt="Twitter icon"
          />
          <span className="maison-book fz15px lh24px hide-sm">
            @keithholjencin
          </span>
        </a>
      </li>
    </ul>
  )
}

export default ContactDetails
